<mat-expansion-panel class="mat-elevation-z0">
  <mat-expansion-panel-header *ngIf="this.view.fetchState | async as state" [xOperation]="state">
    <mat-panel-title>Sage Order Sync</mat-panel-title>
    <mat-panel-description>
      {{ state.data?.status ?? state.error?.title }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <table *ngIf="view.data as sync">
      <tbody>
        <tr>
          <th>Sync Status</th>
          <td>{{ sync.status }}</td>
        </tr>
        <tr *ngIf="sync.error">
          <th>Error</th>
          <td style="max-width: 300px">{{ sync.error }}</td>
        </tr>
        <tr>
          <th>Last Synced</th>
          <td>{{ sync.lastSyncedAt }}</td>
        </tr>
        <tr *ngIf="sync.diff">
          <th>Differential</th>
          <td style="max-width: 300px">
            <pre>
              <code>{{ sync.diff | json }}</code>
            </pre>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="view.lastMutation?.isErrorState()"
      class="p-2"
      style="color: white; background-color: red"
    >
      {{ view.lastMutation?.error?.title }}
      <br />
      {{ view.lastMutation?.error?.message }}
    </div>
    <mat-action-row>
      <mat-spinner
        color="accent"
        diameter="20"
        *ngIf="view.fetchLoading || view.mutationLoading"
      ></mat-spinner>

      <mat-slide-toggle [checked]="view.data?.enabled ?? false" (change)="update($event.checked)">
        Enable
      </mat-slide-toggle>
      <button mat-flat-button color="warn" (click)="delete()" [disabled]="view.mutationLoading">
        Delete
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="sync()"
        [disabled]="!(view.data?.enabled ?? false) || view.mutationLoading"
      >
        Sync
      </button>
    </mat-action-row>
  </ng-template>
</mat-expansion-panel>
